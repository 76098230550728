import React, { useReducer } from 'react'
import { lockedReducer } from '../../../../utils/defaultReducer'
import { FormControl, InputLabel, Select, Box, MenuItem } from '@mui/material'
import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import ptBR from 'dayjs/locale/pt-br'
import { ptBR as localePTBR } from '@mui/x-date-pickers/locales'
import LoadingButton from '@mui/lab/LoadingButton';
import TableRowsIcon from '@mui/icons-material/TableRows';
import GraphsViewV2 from '.'
import './styles.css'

const types = {
  day: 'Dia',
  range: 'Intervalo',
  month: 'Mês'
}

let getGraph = () => {}

const GraphsFilter = () => {
  const [state, setState] = useReducer(lockedReducer, {
    type: 'day',
    day: dayjs(),
    range_ini: dayjs().subtract(1, 'month'),
    range_end: dayjs(),
    month: dayjs()
  })

  

  return (
    <LocalizationProvider
      sx={{ justifyContent: 'space-between' }}
      dateAdapter={AdapterDayjs}
      adapterLocale={ptBR}
      localeText={
        localePTBR.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <Box className='graph-reports-filter'>
        <GraphsViewV2 setGraph = {(func) => {getGraph = func}}/>
        <FormControl size='small' sx={{ minWidth: '10rem' }}>
          <InputLabel id='report-type'>Filtro</InputLabel>
          <Select
            labelId='report-type'
            id='report-type'
            value={state.type}
            label='Filtro'
            onChange={e => setState({ type: e.target.value })}
          >
            {Object.entries(types).map(([key, value]) => (
              <MenuItem value={key}>{value}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {state.type === 'day' && (
          <DatePicker
            sx={{ width: '100%', maxWidth: '10rem' }}
            label={'Dia'}
            views={['day', 'month', 'year']}
            format={'DD/MM/YYYY'}
            slotProps={{ textField: { size: 'small' } }}
            value={state.day}
            onChange={e => setState({ day: e })}
          />
        )}
        {state.type === 'range' && (
          <>
            <DateTimePicker
              sx={{ width: '100%', maxWidth: '13rem' }}
              label={'De'}
              views={['day', 'month', 'year', 'hour', 'minutes']}
              format={'DD/MM/YYYY HH:mm'}
              slotProps={{ textField: { size: 'small' } }}
              value={state.range_ini}
              onChange={e => setState({ range_ini: e })}
            />
            <DateTimePicker
              sx={{ width: '100%', maxWidth: '13rem' }}
              label={'Até'}
              views={['day', 'month', 'year', 'hour', 'minutes']}
              format={'DD/MM/YYYY HH:mm'}
              slotProps={{ textField: { size: 'small' } }}
              value={state.range_end}
              onChange={e => setState({ range_end: e })}
            />
          </>
        )}
        {state.type === 'month' && (
          <DatePicker
            sx={{ width: '100%', maxWidth: '13rem' }}
            label={'Mês e ano'}
            views={['month', 'year']}
            format={'MMMM, YYYY'}
            slotProps={{ textField: { size: 'small' } }}
            value={state.month}
            onChange={e => setState({ month: e })}
          />
        )}

        <LoadingButton
          onClick={() => {
                getGraph(state)
          }}
          loadingPosition='start'
          loading={false}
          variant='contained'
          startIcon={<TableRowsIcon />}
        >
          Pesquisar
        </LoadingButton>
      </Box>
    </LocalizationProvider>
  )
}

export default GraphsFilter
