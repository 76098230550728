import React from 'react';

import { IMaskInput } from 'react-imask';

import {
    Dialog, 
    DialogTitle, 
    DialogContent,
    DialogActions, 
    Button, 
    MenuItem, 
    FormControl, 
    Divider, 
    Tooltip,
    TextField, 
    Select, 
    InputLabel,
    FormHelperText,
    Box,
    Autocomplete,
    CircularProgress
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import CloseIcon from '@mui/icons-material/Close';

import MultipleSelections from '../../Input/MultipleSelections';

import api from '../../../api';
import { getSession } from '../../../auth';

import './index.css';

let timeoutId

class ClientDialog extends React.Component {

    constructor(props) {
        super(props);

        this.queues = {};

        this.state = {
            name                 : "",
            email                : "",
            initialEmail         : "",
            telephone            : "",
            initialTelephone     : "",
            customer_code        : "",
            enterpriseName       : "",
            enterpriseCNPJ       : "",
            userFields           : {},
            advisor              : "",
            customFields         : [],
            toCreateCustomFields : [],
            notificationOptOut   : false,
            groupsSelectOpen     : false,
            groupsloading        : false,
            contactGroups        : [],
            selectedGroups       : []
        }
    }

    componentDidMount() {
        if (this.props.type === 'create') 
            this.createClient();
        else 
            this.changeClient();
    }

    createClient = () => {
        // Handle Zendesk user fields
        let userFields = this.props.model ? this.props.model.user_fields : {};
        
        if (userFields) {
            Object.keys(userFields).forEach((key) => {
                userFields[key] = "";
            });
        } else { userFields = {}; }

        this.setState({ 
            userFields: userFields,
            ...this.props.model 
        });
    }

    changeClient = () => {
        let userFields = this.props.model ? this.props.model.user_fields : {};
        
        if (! userFields) userFields = {} // Avoid undefined render error

        let name               = '';
        let email              = '';
        let telephone          = '';
        let initialTelephone   = '';
        let customer_code      = '';
        let enterpriseName     = '';
        let enterpriseCNPJ     = '';
        let advisor            = '';
        let customFields       = [];
        let notificationOptOut = false;

        if (this.props.model) {
            name               = this.props.model.name;
            email              = this.props.model.email;
            customer_code      = this.props.model.customer_code;
            telephone          = this.props.model.telephone;
            enterpriseName     = this.props.model.enterprise_name;
            enterpriseCNPJ     = this.props.model.enterprise_cnpj;
            advisor            = this.props.model.advisor;
            notificationOptOut = this.props.model.notification_opt_out;

            if (this.props.model.custom_fields)
                customFields = JSON.parse(JSON.stringify(this.props.model.custom_fields))
        };

        this.setState({
            name               : name,
            email              : email,
            initialEmail       : email,
            telephone          : telephone,
            initialTelephone   : telephone,
            customer_code      : customer_code,
            notificationOptOut : notificationOptOut,
            enterpriseName     : enterpriseName,
            enterpriseCNPJ     : enterpriseCNPJ,
            userFields         : userFields,
            advisor            : advisor,
            customFields       : customFields,
            selectedGroups     : [...this.props.model.groups]
        });
    }

    setUserField = (key, value) => {
        const userFields = this.state.userFields;

        userFields[key] = value;

        this.setState({
            userFields: userFields
        });
    }

    submit = () => {
        let telephone = this.state.initialTelephone;
        let email = this.state.initialEmail;
        let advisor = this.state.advisor;

        if (!this.disabledInput('initialTelephone'))
            telephone = this.state.telephone;

        if (!this.disabledInput('initialEmail'))
            email = this.state.email;

        if (this.props.type === 'create') {
            this.props.submit(
                this.state.name,
                email,
                telephone,
                this.state.userFields,
                advisor,
                this.state.enterpriseName,
                this.state.enterpriseCNPJ,
                this.state.customer_code,
                this.state.selectedGroups.map((group) => group.id),
                this.state.toCreateCustomFields,
                this.state.notificationOptOut
            );
        } else {
            this.props.submit(
                this.props.model ? this.props.model.id : null,
                this.state.name,
                this.state.initialEmail,
                email,
                this.state.initialTelephone,
                telephone,
                this.state.userFields,
                advisor,
                this.state.enterpriseName,
                this.state.enterpriseCNPJ,
                this.state.customer_code,
                this.state.selectedGroups.map((group) => group.id),
                this.state.customFields,
                this.state.toCreateCustomFields,
                this.state.notificationOptOut
            );
        }
    }

    capitalize = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    telephoneMask = React.forwardRef(function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask={[
                    {
                        mask: '(00) 0000-0000',
                        overwrite: true,
                    }, {
                        mask: '(00) 00000-0000',
                        overwrite: true,
                    },
                ]}
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    });

    cnpjMask = React.forwardRef(function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask={[
                    {
                        mask: other?.value?.length > 14 ? '00.000.000/0000-00' : '000.000.000-00000',
                        overwrite: true,
                    }
                ]}
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    });

    telephoneMaskComponent = () => {
        if (this.props.crm_integrated && this.state.initialTelephone)
            return null;

        return this.telephoneMask;
    }

    cnpjMaskComponent = () => {
        return this.cnpjMask;
    }

    disabledInput = (initialStateName) => {
        return this.props.crm_integrated && this.state[initialStateName];
    }

    infoInputBlocked = () => {
        return (
            <FormHelperText id="component-helper-text" className="clients-helper-text">
                Só é possível alterar o valor acima pelo CRM.
            </FormHelperText>
        );
    }

    getCustomValue = (field) => {
        if (this.state.customFields.length === 0 && this.state.toCreateCustomFields === 0)
            return ''

        const customField = this.state.customFields.find((row) => row.custom_field.id === field.id)
        if (customField)
            return customField.value

        const toCreateCustomField = this.state.toCreateCustomFields.find((row) => row.custom_field.field_name === field.field_name)
        if (toCreateCustomField)
            return toCreateCustomField.value

        return ''
    }

    updateCustomField = (field, value) => {
        const customFields = this.state.customFields
        const toCreateCustomFields = this.state.toCreateCustomFields

        let found = false
        for (const customField of customFields) {
            if (customField.custom_field.id === field.id) {
                found = true
                customField.value = value
            }
        }

        for (const toCreateCustomField of toCreateCustomFields) {
            if (toCreateCustomField.custom_field.field_name === field.field_name) {
                found = true
                toCreateCustomField.value = value
            }
        }

        const newCustomFields = []
        if (! found) {
            // We need to create a list of custom fields to add
            newCustomFields.push({
                custom_field : field,
                value        : value
            })
        }

        this.setState({ 
            customFields: customFields,
            toCreateCustomFields: [...toCreateCustomFields, ...newCustomFields]
        })
    }

    renderCustomField = (field) => {
        switch (field.field_type) {
            case 'string':
                return (
                    <TextField
                        size="small"
                        label={field.field_name}
                        value={this.getCustomValue(field)}
                        fullWidth
                        type="text"
                        variant="outlined"
                        className="push-top"
                        onChange={(event) => this.updateCustomField(field, event.target.value)}
                    />
                )
            break;
            case 'integer':
                return (
                    <TextField
                        size="small"
                        label={field.field_name}
                        value={this.getCustomValue(field)}
                        fullWidth
                        type="number"
                        variant="outlined"
                        className="push-top"
                        onChange={(event) => this.updateCustomField(field, event.target.value)}
                    />
                )
            break;
            case 'boolean':
                return (
                    <FormControl fullWidth size="small" className="push-top">
                        <InputLabel id="custom-field-type-select">{field.field_name}</InputLabel>
                        <Select
                            labelId="custom-field-type-select"
                            value={this.getCustomValue(field)}
                            label={field.field_name}
                            onChange={(event) => this.updateCustomField(field, event.target.value)}
                        >
                            <MenuItem value=""></MenuItem>
                            <MenuItem value="1">Sim</MenuItem>
                            <MenuItem value="0">Não</MenuItem>
                        </Select>
                    </FormControl>
                )
            break;
        }
    }

    getGroups = (search, groupsUrl) => {
        if (groupsUrl === undefined || (typeof search !== 'object' && search !== undefined)) {
            // Reset contactGroups
            this.setState({ 
                groupsSelectOpen : true,
                groupsloading    : true,
                contactGroups    : []
            })
        } else {
            this.setState({ 
                groupsSelectOpen : true,
                groupsloading    : true
            })   
        }

        const enterpriseId = getSession().profile.enterprise.id

        let url
        if (groupsUrl !== undefined)
            url = `/api/${groupsUrl.split('/api/')[1]}`
        else {
            url = `/api/enterprises/${enterpriseId}/contact_group/noserialize/`
            if (typeof search !== 'object' && search !== undefined)
                url = `${url}?search=${search}`   
        }

        api.get(url).then((res) => {
            if (res.status === 200)
                this.setState((prevState) => ({
                    groupsloading: false,
                    nextGroupsPointer: res.data.next,
                    contactGroups: [...prevState.contactGroups, ...res.data.results]
                }))
        }).catch((err) => {})
    }

    handleClose = () => {
        this.setState({ groupsSelectOpen: false })
    }

    handleScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        if (this.state.nextGroupsPointer && scrollHeight - scrollTop <= clientHeight + 5)
            this.getGroups(undefined, this.state.nextGroupsPointer)
    }

    render() {
        return (
            <div>
                <Dialog open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="customized-dialog-title"
                    fullWidth
                    disableEnforceFocus
                >
                    <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                        <span>{this.props.title}</span>
                        <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={this.props.handleClose} >
                            <CloseIcon />
                        </Button>
                    </DialogTitle>
                    <Divider />
                    <DialogContent
                        ref={instance => { this.content = instance; }}>
                        <TextField
                            size="small"
                            label="Nome"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={this.state.name}
                            onChange={(event) => this.setState({
                                name: event.target.value
                            })}
                        />
                        <TextField
                            size="small"
                            label="Email"
                            fullWidth
                            type="text"
                            variant="outlined"
                            className="push-top"
                            value={this.state.email}
                            onChange={(event) => this.setState({
                                email: event.target.value
                            })}
                            disabled={this.disabledInput('initialEmail')}
                        />
                        {this.disabledInput('initialEmail') && this.infoInputBlocked()}
                        <Tooltip title='Obrigatório'>
                            <TextField
                                size="small"
                                label="Telefone"
                                fullWidth
                                type="text"
                                variant="outlined"
                                className="push-top"
                                value={this.state.telephone ? this.state.telephone.replace('+55', '') : null}
                                onChange={(event) => this.setState({
                                    telephone: event.target.value
                                })}
                                InputProps={{
                                    inputComponent: this.telephoneMaskComponent()
                                }}
                                disabled={this.disabledInput('initialTelephone')}
                                required
                            />
                        </Tooltip>
                        {this.disabledInput('initialTelephone') && this.infoInputBlocked()}
                        <TextField
                            size="small"
                            label="Código do cliente"
                            fullWidth
                            type="text"
                            variant="outlined"
                            className="push-top"
                            value={this.state.customer_code}
                            onChange={(event) => this.setState({
                                customer_code: event.target.value
                            })}
                        />
                        <TextField
                            size="small"
                            label="Empresa"
                            fullWidth
                            type="text"
                            variant="outlined"
                            className="push-top"
                            value={Array.isArray(this.state.enterpriseName) ? this.state.enterpriseName[0] : this.state.enterpriseName}
                            onChange={(event) => this.setState({
                                enterpriseName: event.target.value
                            })}
                        />
                        <TextField
                            size="small"
                            label="CPF/CNPJ"
                            fullWidth
                            type="text"
                            variant="outlined"
                            className="push-top"
                            value={this.state.enterpriseCNPJ}
                            onChange={(event) => this.setState({
                                enterpriseCNPJ: event.target.value
                            })}
                            InputProps={{
                                inputComponent: this.cnpjMaskComponent() 
                            }}
                        />
                        <FormControl fullWidth size="small" className="push-top">
                            <InputLabel id="notification-opt-out-label">Recebe campanhas</InputLabel>
                            <Select
                                labelId="notification-opt-out-label"
                                value={! this.state.notificationOptOut}
                                label="Recebe campanhas"
                                onChange={(event) => this.setState({ notificationOptOut: ! event.target.value })}
                            >
                            <MenuItem value={true}>Sim</MenuItem>
                            <MenuItem value={false}>Não</MenuItem>
                            </Select>
                        </FormControl>
                        <>
                            <Box className="category-title push-top">Segmentos</Box>
                            <Autocomplete
                                multiple
                                sx={{ marginTop: "1rem", marginBottom: "1rem" }}
                                size="small"
                                open={this.state.groupsSelectOpen}
                                onOpen={this.getGroups}
                                onClose={this.handleClose}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                getOptionLabel={(option) => option.name}
                                options={this.state.contactGroups}
                                loading={this.state.groupsloading}
                                filterOptions={(x) => x}
                                onInputChange={(event) => {
                                    if (timeoutId)
                                        clearTimeout(timeoutId)
                                    timeoutId = setTimeout(() => this.getGroups(event.target.value), 1000)
                                }}
                                value={this.state.selectedGroups}
                                onChange={(event, newValue) => this.setState({ selectedGroups: newValue })}
                                ListboxProps={{ onScroll: this.handleScroll }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Segmentos"
                                        slotProps={{
                                            input: {
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {this.state.groupsloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                            },
                                        }}
                                    />
                                )}
                            />
                        </>
                        {this.props.customFields && this.props.customFields.length !== 0 && (
                            <>
                                <Box className="category-title">Campos customizados</Box>
                                {this.props.customFields.map((field) => this.renderCustomField(field))}
                            </>
                        )}
                        {Object.keys(this.state.userFields).map((key, index) => (
                            <TextField
                                size="small"
                                label={this.capitalize(key.replaceAll('_', ' '))}
                                fullWidth
                                type="text"
                                variant="outlined"
                                className="push-top"
                                value={this.state.userFields[key]}
                                onChange={(event) => this.setUserField(
                                    key,
                                    event.target.value
                                )}
                            />
                        ))}
                        {this.props.is_financial_market_company && this.props.isAdmin &&
                            <FormControl
                                fullWidth
                                size="small"
                                className="push-top"
                            >
                                <InputLabel id="select-label-advisor-label">Assessor</InputLabel>
                                <Select
                                    labelId="select-label-advisor-label"
                                    size="small"
                                    variant="outlined"
                                    label="Assessor"
                                    value={this.state.advisor}
                                    fullWidth
                                    onChange={(event) => { this.setState({ advisor: event.target.value }) }}
                                >
                                    {this.props.listOfUsers.map((item) => (
                                        <MenuItem id={item} value={item}>{item}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        }
                    </DialogContent>
                    <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                        <LoadingButton
                            onClick={this.props.handleClose}
                            color="primary"
                            loading={this.props.submitButtonLoading}
                        >
                            Cancelar
                        </LoadingButton>
                        <LoadingButton
                            onClick={() => this.submit()}
                            color="primary"
                            loading={this.props.submitButtonLoading}
                        >
                            {this.props.buttonText}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

export default ClientDialog;
