import React, { useState, useEffect, useContext, useMemo } from 'react'

import { Box, Tabs, Tab, Badge, Tooltip } from '@mui/material'

import { QueueSocket } from '../../../routes'
import QueueContainer from '../../ManageCalls/QueueContainer'
import api from '../../../api'

import QueueIcon from '@mui/icons-material/Group';
import OngoingIcon from '@mui/icons-material/SupportAgent';
import ReactivationIcon from '@mui/icons-material/Update';
import FinishedIcon from '@mui/icons-material/Done';
import RefreshIcon from '@mui/icons-material/Refresh';
import FilterComponent from '../../ManageCalls/FilterComponent'
import SortComponent from "../../ManageCalls/SortComponent";
import ToogleJustMe from '../../ManageCalls/JustMe'
import { getSession } from '../../../auth'
import useNotification from '../../Notification'
import Loading from '../../Loading'

import * as workerInterval from 'worker-interval';
import FinishedQueueContainer from '../../ManageCalls/FinishedQueueContainer'

let running = false

const subsObj = (objOld, objNew) => {
    const parsed = {}
    objOld.map(each => { parsed[each.id] = each })
    const newobj = {}
    if (Array.isArray(objNew)) {
        objNew.map(each => { newobj[each.id] = each })
        return Object.values({ ...parsed, ...newobj })
    }
    newobj[objNew.id] = objNew
    return Object.values({ ...parsed, ...newobj })
}

const SwitchHover = ({ active, normal, hovering }) => {
    const [hover, setHover] = useState(false)


    return (
        <Box onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            {!hover || !active ?
                <>
                    {normal}
                </>
                :
                <>
                    {hovering}
                </>
            }
        </Box>
    )
}

const TabPanel = (props) => {
    const { children, value, index, sub } = props;
    const tabActive = value == index;
    const tabType = sub ? 'sub-tab-active' : 'tab-active';
    const displayMode = tabActive ? 'block' : 'none'
    return (
        <div className={tabActive ? tabType : null} style={{ display: displayMode }}>
            {
                children
            }
        </div>
    );
}

let notificated = false
var timeout = 0

const allChatsInitialState = {
    queueState: [],
    queueAmount: 0,
    onchatState: [],
    onchatAmount: 0,
    reactivatedState: [],
    reactivatedAmount: 0,
    finishedState: [],
    finishedAmount: 0,
    notificationState: [],
    notificationAmount: 0,
}

const NewConversationList = (props) => {

    const { visible, chatState, sendNotification, notifications, setNotifications } = useContext(QueueSocket)

    const user = getSession();
    const settings = user.profile.enterprise.settings
    const finished_infinite_scroll = user.profile.enterprise.finished_infinite_scroll
    const limit = 6
    const loading = props.loading
    const [newQueue, setNewQueue] = useState(0)
    const [unreadMessages, setUnreadMessages] = useState(0)
    const [refresh, setRefresh] = useState(false)
    const [filterSettings, setFilterSettings] = useState()
    const [outLoading, setOutLoading] = useState(false)

    const [queueAdded, setQueueAdded] = useState(false)
    const [onchatAdded, setOnchatAdded] = useState(false)
    const [reactivatedAdded, setReactivatedAdded] = useState(false)
    const [finishedAdded, setFinishedAdded] = useState(false)
    const [notificationAdded, setNotificationAdded] = useState(false)
    const [heartbeat, setHeartbeat] = useState(false)
    const [initHeartbeat, setInitHeartbeat] = useState(false)

    const [onChatAddedMessages, setOnChatAddedMessages] = useState(0)
    const [onQueueAddedMessages, setOnQueueAddedMessages] = useState(0)

    const [orderBy, setOrderBy] = useState("-id");
    const [justMe, setJustMe] = useState(true)

    const tabNames = ['queue', 'onchat', 'reactivated', 'finished']

    const reloadState = () => {
        setRefresh(p => !p)
    }

    const [allChats, setAllChats] = useState(
        allChatsInitialState
    )

    useEffect(async () => {
        if (!filterSettings || filterSettings.data == '')
            return

        allChats.finishedState = []
        allChats.finishedAmount = 0

        const promises = []
        promises.push(getData('queue'))
        promises.push(getData('onchat'))
        promises.push(getData('reactivated'))
        if (!finished_infinite_scroll)
            promises.push(getData('finished'))
        await Promise.all(promises)

        setQueueAdded(p => !p)
        setOnchatAdded(p => !p)
        setReactivatedAdded(p => !p)
        if (!finished_infinite_scroll)
            setFinishedAdded(p => !p)
        setNotificationAdded(p => !p)
        props.refresh()

    }, [filterSettings])


    const countData = async () => {

        let url = 'api/enterprises/' + props.enterpriseId + `/calls/chat/count/?filtered=True`

        if (filterSettings?.data)
            url = url.concat(filterSettings.data)

        const res = await api.get(url)

        if (res.status == 200)
            return res.data

        return false
    }

    useEffect(() => {

        if (settings.websockets)
            return

        setTimeout(() => {
            workerInterval.setInterval(() => setHeartbeat(p => !p), 5000)
        }, 1000)
        setInitHeartbeat(true)

        window.updateNotify = () => {
            setNotificationAdded(p => !p)
        }
    }, [])


    useEffect(async () => {

        if (running === true)
            return

        if (initHeartbeat) {

            running = true

            const data = await countData()

            const notificate = { queues: 0, messages: 0, chats: 0 }

            if (!data) {
                running = false
                return;
            }

            const new_queued = data.queued
            const new_queued_messages = data.unreadqueuedmessages
            const new_onchat = data.onchat
            const new_onchat_messages = data.unreadonchatmessages
            const new_reactivated = data.reactivated
            const new_finished = data.finished

            let renownqueue = 0

            if (new_queued != allChats['queueAmount']) {
                notificate.queues = new_queued - allChats['queueAmount']
                renownqueue = 1
            }

            if (new_queued_messages != onQueueAddedMessages) {
                renownqueue = 1
                if (new_queued_messages > onQueueAddedMessages)
                    notificate.messages = new_queued_messages - onQueueAddedMessages
            }

            let renewonchat = 0

            if (onChatAddedMessages != new_onchat_messages) {

                if (new_onchat_messages > onChatAddedMessages)
                    notificate.messages += new_onchat_messages - onChatAddedMessages

                renewonchat = 1

                setOnChatAddedMessages(new_onchat_messages)

                if (props.activeChat && props.activeChat.lh_chat_id) {
                    //dont need to wait
                    api.get(`api/enterprises/${props.enterpriseId}/calls/chat/set_read/${props.activeChat.lh_chat_id}/`, { timeout: 0 })
                }
            }

            if (renownqueue === 1) {
                allChats['queueState'] = []
                setOnQueueAddedMessages(new_queued_messages)
                setQueueAdded(p => !p)
            }

            if (new_onchat !== allChats['onchatAmount'])
                renewonchat = 1

            if (renewonchat) {
                allChats['onchatState'] = []
                setOnchatAdded(p => !p)
            }

            if (new_reactivated != allChats['reactivatedAmount']) {
                allChats['reactivatedState'] = []
                setReactivatedAdded(p => !p)
            }

            if (!finished_infinite_scroll && new_finished != allChats['finishedAmount']) {

                if (!filterSettings) {
                    allChats['finishedState'] = []
                    setFinishedAdded(p => !p)
                }

            }

            if (finished_infinite_scroll) {
                allChats['finishedAmount'] = new_finished
            }

            let type = notificate.queues > 0 ? 'newChat' : 'newMessage'

            type = notificate.messages > 0 ? type : null

            const place = []
            if (renownqueue === 1)
                place.push('em fila')
            if (renewonchat)
                place.push('em atendimento')

            if (!notifications && type) {
                if (!visible) {
                    sendNotification(type, type === 'newChat' ?
                        notificate.queues : notificate.chats, notificate.messages, place.join(' e '));
                }
                setNotifications(true)
            }

        }

        running = false
    }, [heartbeat, props.updateList])



    const getData = async (where, offset = 0, channel = 0, newOrder = null) => {
        const ob = newOrder ? newOrder : orderBy;
        let url = ''
        if (where == 'notification' && justMe) {
            url =
                "api/enterprises/" +
                props.enterpriseId +
                `/calls/chat/${where}/?limit=${limit}&offset=${offset}&filtered=true&orderBy=${ob}&justme=true`;
        } else {
            url =
                "api/enterprises/" +
                props.enterpriseId +
                `/calls/chat/${where}/?limit=${limit}&offset=${offset}&filtered=true&orderBy=${ob}`;
        }


        if (channel != 0)
            url = url.concat(`&channel=${channel}`)

        if (filterSettings?.data) {
            url = url.concat(filterSettings.data)
        }

        const res = await api.get(url, { timeout: 0 })
        if (res.status == 200) {

            let results = res.data.results

            //case we are receiving something on websocket
            if (channel != 0)
                return results

            //Add indexes from offset to chats
            results.map((chat, index) => {
                chat.position = offset + index;
                results[index] = { ...chat, ...chat.lhc }
            })

            let newChats = subsObj(allChats[`${where}State`], results)

            const newState = {}
            newState[`${where}State`] = newChats
            newState[`${where}Amount`] = res.data.count

            setAllChats(prevState => ({ ...prevState, ...newState }))

            return true
        }

        return false
    }

    const updateData = async (newOrder = null) => {
        if (newOrder !== undefined) {
            setOrderBy(newOrder);
        }
        setOutLoading(true);

        Object.assign(allChats, allChatsInitialState)

        const where = tabNames[props.activeTab];
        const ret = await getData(where, 0, 0, newOrder);
        if (ret === true) {
            switch (where) {
                case 'queue':
                    setQueueAdded(p => !p);
                    break;
                case 'onchat':
                    setOnchatAdded(p => !p);
                    break;
                case 'reactivated':
                    setReactivatedAdded(p => !p);
                    break;
                case 'notification':
                    setNotificationAdded(p => !p);
                    break;
                case 'finished':

                    setFinishedAdded(p => !p);
                    break;
            }
            props.refresh();
            props.refreshChat();
            reloadState();
        }
        setOutLoading(false);
        return ret;
    };

    const updatejustme = async (justme) => {
        allChats['notificationState'] = []
        allChats['notificationAmount'] = 0
        setNotificationAdded(p => !p);
        setJustMe(justme)
    }

    const addChat = (newchat, where) => {
        if (!newchat || newchat.length == 0)
            return
        const chat = newchat[0]


        allChats[`${where}State`].map(each => { each.position += 1 })
        allChats[`${where}Amount`] += 1
        chat.position = 0
        allChats[`${where}State`] = subsObj(allChats[`${where}State`], chat)


        switch (where) {
            case 'queue':
                setQueueAdded(p => !p)
                break
            case 'onchat':
                setOnchatAdded(p => !p)
                break
            case 'reactivated':
                setReactivatedAdded(p => !p)
                break
            case 'finished':
                setFinishedAdded(p => !p)
                break
            default:
                break
        }
    }

    //always return the removed chat, so we can disable it if it's the same as activeChat if we are not the agent.
    const removeChat = (parameter, where) => {
        let flag = 'channel'

        if (where == 'reactivated')
            flag = 'callerid'

        const foundChat = allChats[`${where}State`].find(oldChat => oldChat[flag] == parameter)

        if (!foundChat)
            return
        allChats[`${where}State`].map(each => {
            if (foundChat.position < each.position)
                each.position -= 1
        })
        allChats[`${where}State`] = allChats[`${where}State`].filter(oldChat => oldChat[flag] != parameter)
        allChats[`${where}Amount`] -= 1

        return foundChat
    }
    const notificateUserQueue = () => {
        const queues = allChats['queueState'].map(each => each.messages.filter(msg => !msg.is_read).length).reduce((a, b) => a + b, 0)
        if (!visible && !notificated) {
            // Hear new message sound

            const audio = new Audio(`${window.location.origin}/new_message.mp3`);
            audio.play();
            sendNotification('newMessage', allChats[`queueAmount`], queues > 0 ? queues : 1);
            notificated = true

        }
        setNewQueue(queues > 0 ? queues : 1)
    }

    //Let's Count unreadMessages
    useEffect(() => {
        const onChats = allChats['onchatState'].map(each => each.unread_messages).reduce((a, b) => a + b, 0)
        setUnreadMessages(onChats)
    }, [allChats, refresh])
    //Unread chats only on init and on refresh.
    useEffect(() => {
        if (props.activeTab == 0)
            allChats['queueState'].map(queue => queue.seen = true)
        const queues = (allChats['queueState'].filter(queue => !queue.seen)).length
        setNewQueue(queues)
    }, [refresh, allChats, props.activeTab])

    //If new chat, we should add it to the respective list accordingly
    const webSocketGetChats = async () => {
        if (chatState && chatState.new) {
            chatState.new = false
            const message = chatState.message
            const channel = chatState.channel
            let newchat = {}
            let removedChat = {}

            switch (message) {
                //Add chat on queue and offset actualchats

                case 'NEW_CHAT':
                    newchat = await getData('queue', 0, channel)
                    addChat(newchat, 'queue')
                    notificateUserQueue()
                    break;
                // Here I sent the chat directly on the message, maybe we should do it like this instead of sending and requesting channels?
                case 'DROP_CHAT':
                    removedChat = removeChat(channel.channel, 'onchat')
                    if (removedChat?.uniqueid == props.activeChat?.uniqueid) {
                        props.setActiveChat({})
                        useNotification(
                            'Informação',
                            `Seu chat foi transferido por ${channel.user}.`,
                            'info'
                        );
                    }
                    reloadState()

                    return
                //Remove chat from queue/reactivated and add onchat, deoffset queue, offset onchat
                case 'ONCHAT':
                    newchat = await getData('onchat', 0, channel)
                    removedChat = removeChat(channel, 'queue')
                    removeChat(newchat[0]?.callerid, 'reactivated') // this case is always per operator.
                    addChat(newchat, 'onchat')
                    if (props.activeChat && newchat.length > 0 && props.activeChat?.channel == newchat[0]?.channel)
                        props.activeChat.agent.name = newchat[0].agent.name
                    break;
                //Here we are receiving a new message on queue or ONCHAT from the client or agent
                //We should get this message on channel and update our chat accordingly, because it already exists
                //But we will only do it for ONCHAT messages, otherwise we will ignore it.
                //We will not do it if we are currently on this chat to avoid doublegetting
                case 'NEW_MESSAGE':
                    const oldchat = allChats['onchatState'].find(chat => chat.channel == channel)
                    if (oldchat && props.activeChat && oldchat.uniqueid != props.activeChat.uniqueid) {
                        const newmsg = await getData('onchat', 0, channel)
                        if (newmsg && newmsg.length > 0) {
                            oldchat.messages = newmsg[0].messages
                            props.refresh()
                            reloadState()
                        }
                    }
                    reloadState()
                    return;
                //Add chat on reactivated and offsetchat
                case 'REACTIVATE':
                    newchat = await getData('reactivated', 0, channel)
                    addChat(newchat, 'reactivated')
                    break;
                //Add chat on finished and offset actualchats
                case 'HANGUP':
                    newchat = await getData('finished', 0, channel)
                    removedChat = removeChat(channel, 'onchat')
                    if (removedChat)
                        addChat(newchat, 'finished')
                    break;
                // Will just reload everything here
                case 'NOTIFICATIONS':
                    if (channel) {
                        allChats[`notificationState`] = allChats[`notificationState`].filter(oldChat => oldChat.id != channel)
                        props.setActiveChat({})
                    }
                    setNotificationAdded(p => !p)
                    await getData('notification', 0)

                    break
                default:
                    break;
            }
            reloadState()

            //drop chat if we are not the one moving it.
            if (newchat[0]?.agents_id != user.profile.agent_id && removedChat?.uniqueid == props.activeChat.uniqueid) {
                props.setActiveChat({})

                if (message == 'ONCHAT')
                    useNotification(
                        'Informação',
                        `O chat foi atendido.`,
                        'info'
                    );
                else if (newchat[0] && !newchat[0].chatstatus && newchat[0].agent_name && removedChat && removedChat?.uniqueid == props.activeChat.uniqueid) {
                    useNotification(
                        'Informação',
                        `O chat foi finalizado por ${newchat[0].agent_name}.`,
                        'info'
                    );
                }
            }
        }
    }
    useEffect(() => {
        webSocketGetChats()
    }, [chatState])


    const itemClicked = async (chat) => {
        let where = tabNames[props.activeTab]
        props.closeProfile();
        props.setActiveChat(chat);

        if (props.activeSubTab === 1 && props.activeTab === 2) where = 'notification'
        if (props.activeTab !== 2 || props.activeSubTab !== 0) {
            setOutLoading(true)
            const newchat = await getData(where, 0, chat.channel ? chat.channel : chat.hash)
            setOutLoading(false)
            if (newchat && newchat.length > 0) {
                chat.messages = newchat[0].messages
                if ('last_user_msg_time' in newchat[0]) {
                    chat.last_user_msg_time = newchat[0].last_user_msg_time
                }
            }
            chat.unread_messages = 0
            props.refresh()
            props.refreshChat()
            reloadState()
        }
    }

    const setActiveTab = (event, tab) => {

        props.closeProfile();
        props.setActiveChat({});
        props.setActiveTab(tab);
        if (tab == 0)
            notificated = false
    }

    const setReadMessages = (messages) => {
        if (messages.length === 0)
            return;

        // Setting unreadMessages is_read
        const unreadMessagesIds = [];
        messages.forEach((message) => {
            unreadMessagesIds.push(message.id);
        });

        api.post('/api/LHC/messages/set_read/', {
            messages: unreadMessagesIds
        }).then((res) => {
            if (res.status === 200)
                messages.map(each => { 'is_read' in each ? each.is_read = true : null })
            reloadState()
            return;
            throw res;
        }).catch((err) => {
            console.error(err);
        });
    }

    const reloadQueue = async (queue) => {
        if (timeout) return

        allChats[`${queue}State`] = []
        queue === 'queue' ? setQueueAdded(p => !p) : setOnchatAdded(p => !p)

        setTimeout(() => { timeout = false }, 1000)
        timeout = true
    }

    const getDataWithJustMe = (...args) => {
        return getData(...args);
    };

    const getDataApiWithJustMe = async () => {
        const newchat = await getData('notification', 0);
    }

    useEffect(() => {
        getDataApiWithJustMe()
    }, [justMe])


    return (
        <>
            <Loading loading={outLoading} />
            <Box id="conversationList-box-1">
                <Box id="conversationList-box-2">
                    <Box id="conversationList-box-3">
                        <Tabs
                            value={props.activeTab}
                            onChange={setActiveTab}
                            className={`tabs-conversation-list`}
                        >
                            <Tab onClick={() => setNewQueue(0)}
                                label={
                                    <SwitchHover
                                        active={props.activeTab == 0}
                                        normal={
                                            <Tooltip title="Fila" placement="bottom" disableInteractive>
                                                <Badge className='lower-badge' color="success" variant="dot" invisible={newQueue === 0}>
                                                    <QueueIcon />
                                                </Badge>
                                            </Tooltip>
                                        } hovering={
                                            <Tooltip title="Recarregar" placement="bottom" disableInteractive>
                                                <Badge className='lower-badge' onClick={() => reloadQueue('queue')} color="success" variant="dot" invisible={newQueue === 0}>
                                                    <RefreshIcon />
                                                </Badge>
                                            </Tooltip>
                                        } />

                                }
                                className="tab badge-unreadMessages"
                            />
                            <Tab
                                label={
                                    <SwitchHover
                                        active={props.activeTab == 1}
                                        normal={
                                            <Tooltip title="Atendimento" placement="bottom" disableInteractive>
                                                <Badge className='lower-badge' color="success" variant="dot" invisible={unreadMessages === 0}>
                                                    <OngoingIcon />
                                                </Badge>
                                            </Tooltip>
                                        }
                                        hovering={
                                            <Tooltip title="Recarregar" placement="bottom" disableInteractive>
                                                <Badge className='lower-badge' onClick={() => reloadQueue('onchat')} color="success" variant="dot" invisible={unreadMessages === 0}>
                                                    <RefreshIcon />
                                                </Badge>
                                            </Tooltip>
                                        } />
                                }
                                className="tab badge-unreadMessages"
                            />
                            <Tab
                                label={
                                    <Tooltip title="Pendente" placement="bottom" disableInteractive>
                                        <ReactivationIcon />
                                    </Tooltip>
                                }
                                className="tab"
                            />
                            <Tab
                                label={
                                    <Tooltip title="Finalizado" placement="bottom" disableInteractive>
                                        <FinishedIcon />
                                    </Tooltip>
                                }
                                className="tab"
                            />
                        </Tabs>
                    </Box>
                    <FilterComponent
                        key='filter-component'
                        mode={2}
                        setFilterSettings={setFilterSettings}
                        reloadState={reloadState}
                        setHeartbeat={setHeartbeat}
                        sortComponent={
                            <SortComponent
                                orderBy={orderBy}
                                updateData={updateData}
                                color="darkGray"
                            />
                        }
                        hideSort={[3, 2, 1].includes(props.activeTab)}
                        tooglejustme={
                            <ToogleJustMe
                                updatejustme={updatejustme}
                                color="darkGray"
                            />
                        }
                        showjustme={[2].includes(props.activeTab)}
                        allGroups={props.allGroups}
                    />
                    <TabPanel value={props.activeTab} index={0}>
                        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                            <Box sx={{ flex: 1 }}>
                                <QueueContainer
                                    queueName={'Fila'}
                                    queueWhere={'queue'}
                                    queueAmount={allChats.queueAmount}
                                    queueRow={allChats.queueState}
                                    onClickItem={itemClicked}
                                    fetching={loading}
                                    limit={limit}
                                    callData={getData}
                                    queueAdded={queueAdded}
                                    listMode={true}
                                    setReadMessages={setReadMessages}
                                    activeChat={props.activeChat}
                                    labels={props.labels}
                                    connectEmailChat={props.connectEmailChat}
                                />
                            </Box>
                        </Box>
                    </TabPanel>
                    <TabPanel value={props.activeTab} index={1}>
                        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                            <Box sx={{ flex: 1 }}>
                                <QueueContainer
                                    queueName={"Atendimentos"}
                                    queueWhere={"onchat"}
                                    queueAmount={allChats.onchatAmount}
                                    queueRow={allChats.onchatState}
                                    onClickItem={itemClicked}
                                    fetching={loading}
                                    limit={limit}
                                    callData={getData}
                                    queueAdded={onchatAdded}
                                    listMode={true}
                                    setReadMessages={setReadMessages}
                                    activeChat={props.activeChat}
                                    labels={props.labels}
                                />
                            </Box>
                        </Box>
                    </TabPanel>
                    <TabPanel value={props.activeTab} index={2}>
                        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                            <Tabs
                                value={props.activeSubTab}
                                className={`tabs-conversation-list`}
                                onChange={(e, tab) => { props.setActiveSubTab(tab) }}
                            >
                                <Tab
                                    sx={{ flex: 1 }}
                                    className="tab"
                                    label={
                                        <Tooltip title="Reativações" placement="bottom" disableInteractive>
                                            <span>Reativações</span>
                                        </Tooltip>
                                    }
                                />
                                <Tab
                                    sx={{ flex: 1 }}
                                    className="tab"
                                    label={
                                        <Tooltip title="Notificações" placement="bottom" disableInteractive>
                                            <span>Notificações</span>
                                        </Tooltip>
                                    }
                                />

                            </Tabs>
                            <TabPanel value={props.activeSubTab} index={0}>
                                <QueueContainer
                                    queueName={'Pendentes'}
                                    queueWhere={'reactivated'}
                                    queueAmount={allChats.reactivatedAmount}
                                    queueRow={allChats.reactivatedState}
                                    onClickItem={itemClicked}
                                    fetching={loading}
                                    limit={limit}
                                    callData={getData}
                                    queueAdded={reactivatedAdded}
                                    listMode={true}
                                    setReadMessages={setReadMessages}
                                    activeChat={props.activeChat}
                                    labels={props.labels}
                                />
                            </TabPanel>
                            {props.activeSubTab == 1 ?
                                <TabPanel value={props.activeSubTab} index={1}>
                                    <QueueContainer
                                        queueName={'Notificações'}
                                        queueWhere={'notification'}
                                        queueAmount={allChats.notificationAmount}
                                        queueRow={allChats.notificationState}
                                        onClickItem={itemClicked}
                                        fetching={loading}
                                        limit={limit}
                                        callData={getDataWithJustMe}
                                        regenerate={() => {
                                            allChats['notificationState'] = []
                                            allChats['notificationAmount'] = 0
                                        }
                                        }
                                        queueAdded={notificationAdded}
                                        listMode={true}
                                        setReadMessages={setReadMessages}
                                        activeChat={props.activeChat}
                                        AsNotification={false}
                                        labels={props.labels}
                                    />
                                </TabPanel>
                                : null}
                        </Box>
                    </TabPanel>
                    <TabPanel value={props.activeTab} index={3}>
                        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                            {!finished_infinite_scroll ? <Box sx={{ flex: 1 }}>
                                <QueueContainer
                                    queueName={'Finalizados'}
                                    queueWhere={'finished'}
                                    queueAmount={allChats.finishedAmount}
                                    queueRow={allChats.finishedState}
                                    onClickItem={itemClicked}
                                    fetching={loading}
                                    limit={limit}
                                    callData={getData}
                                    queueAdded={finishedAdded}
                                    listMode={true}
                                    setReadMessages={setReadMessages}
                                    activeChat={props.activeChat}
                                    labels={props.labels}
                                />
                            </Box>
                                :
                                <FinishedQueueContainer
                                    key={'finished-queue-container'}
                                    onClickItem={itemClicked}
                                    isManageCalls={false}
                                    activeChat={props.activeChat}
                                    queueAmount={allChats.finishedAmount}
                                    filterSettings={filterSettings}
                                />
                            }
                        </Box>
                    </TabPanel>
                </Box>
            </Box>
        </>
    )
}


export default NewConversationList